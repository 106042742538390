import Carousel from 'stimulus-carousel'

export default class extends Carousel {
    connect() {
        super.connect()
    }

    // You can set default options in this getter.
    get defaultOptions() {
        return {
            spaceBetween: 30,
            slidesPerView: "auto",
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            pagination: false /*{
                el: ".clients-swiper-pagination",
                clickable: true,
                bulletClass: "fr-swiper-pagination-bullet",
                bulletActiveClass: "fr-swiper-pagination-bullet-active"
            }*/,
        }
    }
}
