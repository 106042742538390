import Carousel from 'stimulus-carousel'

export default class extends Carousel {
    connect() {
        super.connect()
    }

    // You can set default options in this getter.
    get defaultOptions() {
        return {
            lazy: true,
            navigation: {
                nextEl: '.frswiper-button-next',
                prevEl: '.frswiper-button-prev',
            },
            autoplay: {
                delay: 10000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            pagination: {
                el: ".banner-swiper-pagination",
                clickable: true,
                bulletClass: "fr-swiper-pagination-bullet",
                bulletActiveClass: "fr-swiper-pagination-bullet-active"
            },
        }
    }
}
