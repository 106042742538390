import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        this.loadWidget();
    }

    loadWidget() {
        const script = document.createElement("script");
        script.src = "https://api.fast-report.com:8088/client/v2/fastchat_client.js";
        script.onload = () => window.fastchatInit();
        document.head.appendChild(script);
    }
}
