import Carousel from 'stimulus-carousel'

export default class extends Carousel {
    connect() {
        super.connect()
    }

    // You can set default options in this getter.
    get defaultOptions() {
        return {
            spaceBetween: 20,
            slidesPerView: 'auto',
            pagination: {
                el: ".delivery-swiper-pagination",
                clickable: true,
                centered: true,
                bulletClass: "fr-swiper-pagination-bullet",
                bulletActiveClass: "fr-swiper-pagination-bullet-active",
                centeredSlides: true,
                centeredSlidesBounds: true,
                /*breakpoints: {
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 30
                    },
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 40
                    }
                }*/
            },
        }
    }
}
